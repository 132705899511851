import pkg from '../../package.json';

export default {
  address: {
    contactNumberLength: 0,
    contactNumberMinLength: 0,
    contactNumberStartWithZero: false,
    emailRequired: true,
    hidePin: true,
    hideCountry: true,
    fixCountry: '',
    pinCodeRequired: false,
    pinCodeLength: 0,
    pinCodeDigitOnly: false,
    addressLine2Mandatory: false,
    countryCode: '',
  },
  allowInsufficientPoint: false,
  allowMultipleEvoucherRedemptions: true,
  allowPayment: false,
  app: 'pwa',
  applyBgColorFromDjango: true,
  appName: 'CerraPoints',
  orgSlug: '',
  isUserRewardPreferenceEnabled: false,
  appreciate: {
    label: 'common.thanks',
    backButtonRoute: 'feeds',
  },
  appreciation: {
    autoReflectCcUser: true,
  },
  contactInfo: {
    en: '',
    zn_HK: '',
  },
  contactEmail: '',
  contactNumber: '',
  customFeeds: false,
  customHomeRoute: null,
  customRewardCategory: false,
  customWelcome: false,
  consentText: true,
  debug: {
    api: {
      showFailedStatusCodeInToastr: false,
      showFailedStatusCodeInBrowserConsole: false,
    },
  },
  defaultBgColor: '#202970',
  defaultCurrency: '',
  defaultLanguage: 'en',
  disableRegisterBtn: false,
  enforceDefaultLanguage: false,
  defaultUserIcon: '',
  downloadNominationCertificate: false,
  drawerBtnLabel: 'common.sort._',
  electricityTopup: {
    numberOfDigits: 11,
  },
  employeeIdPrefix: '',
  enableNomination: true,
  expiryPoint: false,
  expiringPointQueryString: '',
  fallbackRewardIcon: 'assets/img/fallback-rewards.png',
  feeds: {
    label: 'common.feeds',
    path: 'feeds',
    postPolls: false,
  },
  filters: {
    country: true,
  },
  filterByPoints: true,
  filterByRating: false,
  loginPoweredByText: false,
  loginOpenGiftRedemption: false,
  loginDownloadPdf: false,
  footer: {
    dynamicLogo: true,
    logoLink: '',
    privacyLink: '/about/privacy',
    tncLink: '/about/terms-of-use',
    userGuide: '',
    showContactUS: true,
    showStaticContactUS: false,
    showResource: true,
    showAbout: true,
    showContactUsMsg: false,
    getContactDetailsFromOrg: false,
  },
  forgotPassword: {
    emailPatternRequired: true,
    mobileNumberRequired: false,
    emailRequired: true,
    employeeIdRequired: false,
  },
  formatHtml: true,
  freshchat: {
    isShow: true,
    pages: 'all',
  },
  forgotPasswordLink: true,
  giphy: {
    apiKey: 'sUhGOw62fGSyGbWUT0hrlsfLL3gBMQ3h',
  },
  greeting: {
    showYearOfBirth: true,
  },
  goToSsoLinkOnLogout: false,
  header: {
    dynamicBg: true,
    dynamicLogo: true,
    firstLogo: true,
    secondLogo: '',
    pointDetail: false,
    notification: true,
    showDepartmentInProfile: false,
    isIconSvg: true,
    showProfile: true,
  },
  headerLogoMaxHeight: '48px',
  hideRewardsTnC: false,
  hideSFStoreSFLocker: true,
  home: {
    featuredRewards: true,
    topRewards: false,
    recommendation: true,
  },
  infiniteStockEVoucher: false,
  introIcon: 'assets/img/panasonic.png',
  isExternalUrlLoginSupport: true,
  isSiteUnderMaintenance: false,
  isSSOLoginSupport: false,
  isPushNotificationSupport: false,
  krisFlyerTnC:
    'https://www.singaporeair.com/en_UK/ppsclub_krisflyer/termsconditions-kf/',
  languageAvailable: [
    'ar',
    'en',
    'es',
    'fr',
    'hi',
    'id',
    'ja',
    'km',
    'ko',
    'nl',
    'th',
    'vi',
    'zh-hant',
    'zh-hans',
  ],
  languageLabel: {
    ar: 'العربية',
    en: 'English',
    es: 'Español',
    fr: 'Français',
    hi: 'हिंदी',
    id: 'Indonesia',
    ja: '日本語',
    km: 'ខ្មែរ',
    ko: '한국인',
    nl: 'Nederlands',
    th: 'แบบไทย',
    vi: 'Tiếng Việt',
    'zh-hant': '繁體中文',
    'zh-hans': '粵語',
  },
  rtlLang: ['ar'],
  bottomMenu: ['home', 'settings'],
  leftNavigate: [
    'home',
    'feeds',
    'rewards',
    'receipts',
    'sales-receipts',
    'flexiBenefits',
    'leaderboard',
    'settings',
  ],
  links: {
    sfLocker: '#',
    sfStore: '#',
  },
  loginModal: false,
  loginPage: true,
  loginReadMore: false,
  loginTermsCondition: false,
  loginViaUserName: true,
  mobileTopup: {
    mobileNumberMaxDigit: 11,
    mobileNumberMinDigit: 6,
  },
  modules: {
    home: true,
    appreciate: true,
    feeds: true,
    rewards: true,
    myWallet: false,
    settings: true,
    pointsHistory: false,
    cart: true,
    receipts: true,
    salesReceipts: true,
    leaderboard: true,
    flexiBenefits: true,
    calendar: false,
  },
  goToAdminDashboard: true,
  myFeeds: {
    approvals: true,
    myRecommendations: true,
  },
  myNominations: {
    useMergeList: false,
  },
  myWallet: {
    filter: {
      category: 'multiple',
      status: 'single',
    },
  },
  nomination: {
    approvalComment: true,
    badge: true,
    messageToReviewer: true,
    ccUser: true,
    privacy: true,
    preview: true,
    term: false,
  },
  panel: {
    link: ['/profile'],
    support: false,
    signOut: true,
    viewProfile: true,
    dealerManagement: false,
    showDepartment: false,
  },
  passwordValidationPattern:
    '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[A-Za-zd$@$!%*?&].{8,}',
  pointDetailTitle: 'common.point.n',
  pointDetailHeader: 'common.pointsEarned',
  pointHistoryShowPointSideBySide: true,
  profileEdit: {
    changeAvatar: false,
    changePicDisabled: false,
    editFormDisabled: false,
    navigateToHome: false,
    departmentName: false,
    officialTitle: true,
    taxId: false,
    taxPercentage: false,
    registrationDate: false,
    addressLine1Mandatory: false,
    addressLine2Mandatory: false,
    showEditIcon: true,
    phoneNumberDisabled: false,
  },
  randomizeRecommendation: false,
  rating: true,
  recentRecognition: false,
  receipt: {
    showBenefitFor: true,
    showRemarks: true,
    showUserGuide: false,
    prefillBenefitFor: false,
  },
  showTierOnRewards: false,
  recognitionRecommendation: false,
  redeemedSection: 'no-margin-bottom',
  registerNewAccount: true,
  registrationCompletedImg: 'assets/img/mail.png',
  remainingBudgetPoint: false,
  removeRewardRedemption: false,
  rewards: {
    detailQty: 1,
    seeAll: {
      subcategory: 'drawer',
    },
    filters: {
      points: true,
      pointsCollapsible: true,
      rating: true,
      MAX_POINTS: 150000,
    },
    hideTab: false,
    sorting: true,
    title: 'common.reward.n',
  },
  rewardDefaultSort: 'lh',
  rewardDetailBackRoute: ['/rewards/search'],
  search: { helpHeader: '', helpTitle: '', helpDesc: '' },
  searchSubcategory: true,
  registerWithEmployeeId: true,
  registerWithLineId: false,
  settings: {
    hideMyAppreciationMessagesToggle: true,
    makeMyBirthdayPublicToggle: true,
    makeMyAnniversaryPublicToggle: true,
    makeMyProfilePublicToggle: true,
    passwordChangeShow: true,
    passwordChangeStyle: 'float',
  },
  showBottomMenu: true,
  showActiveDotInBottomMenu: true,
  showDepartmentName: false,
  showEmployeeId: false,
  showExpiringPoints: false,
  showYearlyExpiringPoints: false,
  showFooter: '1',
  showFooterContactUsDialog: false,
  showPointCategories: false,
  showPoints: true,
  showPointsOnMyWallet: true,
  showPointsOnHome: {
    available: true,
    earned: true,
    redeemed: true,
  },
  showLanguageChangeIcon: true,
  showBackButton: true,
  showPageSize: true,
  showServiceBanner: false,
  showSpecialDeal: true,
  showUsername: true,
  showRememberMe: false,
  showRegistrationPointsPage: false,
  sideCarousel: [
    {
      img: 'assets/img/slider/1.png',
    },
    {
      img: 'assets/img/slider/2.png',
    },
    {
      img: 'assets/img/slider/3.png',
    },
  ],
  signUpCode: '',
  skipPassword: true,
  sortByName: true,
  sortByPoint: true,
  staticBanner: false,
  strengthName: 'Strength',
  isSupportedMediaInComment: false,
  tenor: {
    apiKey: '',
    clientKey: '',
  },
  thankMessageLeftBox: false,
  tips: {
    count: 10,
    select: null,
  },
  topMenu: false,
  transactionHistoryRewardNameTranslatable: false,
  unauthorizedErrorMessage:
    'Please close this window and click the reward button again from Line OA.',
  useExternalUrlLoginOnly: false,
  ver: pkg.version,
  verShow: false,
  voucherStatus: [0, 1, 2, 9, 11],
  whoToThankTab: false,
  zhLang: 'zh-hant',
  showJobFamily: false,
  userRegistration: {
    contactNumberMinLength: 0,
    contactNumberMaxLength: 0,
  },
  enableHeuristics: false,
  salesReceiptFormItems: {
    orderNum: false,
    cashier: false,
    itemDetails: {
      brand: true,
      subCategory: true,
      brandModel: true,
      quantity: true,
      amount: true,
    },
    itemsMap: {},
  },
  showReceiptNumOnTransactions: false,
  showSessionTimer: false,
  showSessionTimerNote: false,
  showBackToWebsiteBtn: false,
  showAppreciationMsgText: false,
  // Remove this once bottom menu fix is done
  showMoreButtonInBottomMenu: true,
  showMobileTimer: false,
  // White Label Specific
  provinces: {},
  pageSizes: [12, 21, 51],
};
