import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { Store } from '@ngrx/store';
import { AuthData } from 'src/app/utility/model/token-auth';
import { environment } from 'src/environments/environment';
import {
  getFullName,
  getMyNameAcronym,
  getNameAcronym,
  getProfPic,
  toggleStyleDisplay,
} from '../../utility/common-logic';
import { LangService } from 'src/app/services/lang.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  authData: Partial<AuthData> = {};
  imgUrl = environment.url.img;
  style: Partial<CSSStyleDeclaration> = { display: 'none' };
  isBSTLApp = environment.settings.app === 'bstl';
  toggleStyleDisplay = toggleStyleDisplay;

  constructor(
    private authDataStore: Store<{ authData: AuthData }>,
    public lang: LangService,
    private renderer: Renderer2,
    private el: ElementRef,
  ) {}

  ngOnInit() {
    this.authDataStore
      .select('authData')
      .subscribe((res: Partial<AuthData>) => (this.authData = res));
  }

  get profPic() {
    return getProfPic(this.authData);
  }

  get acronym() {
    if (this.isBSTLApp) {
      return getNameAcronym(
        this.authData?.tokenAuth?.user_details?.department_name,
      );
    } else {
      return getMyNameAcronym(this.authData);
    }
  }

  get fullName() {
    return getFullName(this.authData);
  }

  clickOnProfile(style) {
    const container = document.querySelector('.page-content');
    if (style.display == 'block') {
      this.renderer.addClass(container, 'mat-content-layer');
    } else {
      this.renderer.removeClass(container, 'mat-content-layer');
    }
  }
}
