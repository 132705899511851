import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-translate',
  templateUrl: './translate.component.html',
  styleUrl: './translate.component.scss',
})
export class TranslateComponent {
  @Input() isTranslated;
  @Input() isTranslatedOnSamePage;
  @Input() translatingLabel: boolean;
  @Input() translatingLabelOnSamePage: boolean;
  @Input() translatingOnSamePage: boolean = false;
  @Output() translating = new EventEmitter();
  constructor(
    private shared: SharedService,
    private translate: TranslateService,
  ) {}

  onTranslation() {
    if (this.translatingOnSamePage) {
      this.translating.emit(!this.isTranslatedOnSamePage);
    } else {
      this.translating.emit(!this.isTranslated);
    }
  }

  get translateLabel() {
    if (this.translatingLabel) {
      return this.translate.instant('common.pleaseWait');
    } else {
      return this.translate.instant(
        !this.isTranslated ? 'common.translate' : 'common.seeOriginal',
      );
    }
  }

  get translateLabelSamePage() {
    if (this.translatingLabelOnSamePage) {
      return this.translate.instant('common.pleaseWait');
    } else {
      return this.translate.instant(
        !this.isTranslatedOnSamePage
          ? 'common.translate'
          : 'common.seeOriginal',
      );
    }
  }
}
