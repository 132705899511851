import { CartRewardDetail } from './model/cart';
import { TranslateService } from '@ngx-translate/core';
import { ContactDetails, Reward } from './model/reward';
import { SpecialDealItem } from './model/special-deal';
import { environment } from '../../environments/environment';
import { FinanceTransactionsResult } from './model/finance-transactions-response';
import { Announcement, Greeting } from './model/calendar';
import { Event } from './model/dashboard-data';
import { CcUser, Strength, UserPhoto, UserProfile } from './model/user-profile';
import { FinanceTransactionsPointCategories } from './model/finance-transactions-point-summary-response';
import {
  GreetingType,
  NominationStatus,
  ReactionTypeEnum,
  RedemptionType,
  VoucherStatus,
  ReceiptStatus,
  RewardTiers,
  RewardTiersColors,
  AvailableFor,
  NominationStatusWord,
  RatingProcess,
  PeriodType,
} from './enum';
import { ToastrService } from 'ngx-toastr';
import { LangService } from '../services/lang.service';
import { SafeHtml, Title } from '@angular/platform-browser';
import { Badge, NominationCategory, NominationItemA } from './model/nomination';
import { Router } from '@angular/router';
import { clearLocalStorage } from './local-storage';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { AuthData } from './model/token-auth';
import { PostPollResult } from './model/post-poll';
import { isObject } from 'white-labelling/merge-deep';

declare const Buffer: any;
const _imgUrlPrefix = environment.url.img;
const _debug = environment.settings.debug;
const _voucherStatus = environment.settings.voucherStatus;

dayjs.extend(utc);
dayjs.extend(timezone);

export interface ObjectI {
  key: string;
  value: (string | number)[];
}

export function imgUrlPrefix(path: string) {
  return (path.startsWith(_imgUrlPrefix) ? '' : _imgUrlPrefix) + path;
}

export function renderImg(
  imgUrl: string | null | undefined = undefined,
): string {
  if (!imgUrl) {
    return environment.settings.fallbackRewardIcon;
  }
  return imgUrlPrefix(imgUrl);
}

export function renderImg1(
  reward: Reward | SpecialDealItem | undefined,
): string {
  if (!reward) {
    return environment.settings.fallbackRewardIcon;
  }
  return renderImg(reward?.display_img_url);
}

export function renderImg2(reward: ContactDetails | undefined): string {
  if (!reward) {
    return environment.settings.fallbackRewardIcon;
  }
  return renderImg(reward?.user_img);
}

export function renderImg3(item: Announcement | Event | undefined): string {
  if (!item) {
    return environment.settings.fallbackRewardIcon;
  }
  if (!!item?.display) {
    return renderImg(item?.display);
  }
  return renderImg(item?.thumbnail);
}

export function renderImg4(item: Greeting | undefined): string {
  if (!item) {
    return environment.settings.fallbackRewardIcon;
  }
  return renderImg(item?.thumbnail);
}

export function renderImg5(item: UserProfile | undefined): string {
  if (!item) {
    return '';
  }
  return renderImg(item?.profile_img);
}

export function renderImg6(
  item: Strength | FinanceTransactionsPointCategories | Badge | undefined,
): string {
  if (!item) {
    return environment.settings.fallbackRewardIcon;
  }
  return renderImg(item?.icon);
}

export function renderImg7(item: UserProfile | undefined): string {
  if (!item) {
    return environment.settings.fallbackRewardIcon;
  }
  return renderImg(item?.profile_pic_url);
}

export function renderImg8(
  item: FinanceTransactionsResult | undefined,
): string {
  if (!item) {
    return environment.settings.fallbackRewardIcon;
  }
  return !item?.icon ? renderImg(item?.reward_image_url) : item?.icon;
}

export function renderImg9(item: Reward | undefined): string {
  if (!item) {
    return environment.settings.fallbackRewardIcon;
  }
  return renderImg(item?.reward_display_img_url);
}

export function renderImg10(item: UserPhoto | undefined): string {
  if (!item) {
    return environment.settings.fallbackRewardIcon;
  }
  return renderImg(item?.display_url);
}

export function renderImg11(item: NominationCategory | undefined): string {
  if (!item) {
    return environment.settings.fallbackRewardIcon;
  }
  return renderImg(item?.img);
}

export function renderImg12(item: NominationItemA): string {
  if (!item) {
    return environment.settings.fallbackRewardIcon;
  }
  return renderImg(item?.nominated_user_img);
}

export function isQtyInsufficient(
  qty?: string | number | null,
  lowQty?: number | null,
  stock?: number | null,
) {
  if ((stock === -1 && !qty) || qty === null) {
    return false;
  }
  qty = Number(qty);
  if (qty === 0) {
    return true;
  } else if (lowQty && qty && qty <= lowQty) {
    return true;
  } else {
    return false;
  }
}

export function quantity(
  translate: TranslateService,
  qty?: string | number | null,
  lowQty?: number | null,
  stock?: number | null,
) {
  let msg = '';
  if ((stock === -1 && !qty) || qty === null || !qty) {
    return null;
  }
  qty = Number(qty);
  if (qty === 0) {
    msg = translate.instant('msg.qty.empty');
  } else if (lowQty && qty && qty <= lowQty) {
    msg = translate.instant('msg.qty.less', { qty });
  } else {
    msg = translate.instant('msg.qty.more', { qty });
  }
  return '(' + msg + ')';
}

export function transactionName(transaction: FinanceTransactionsResult) {
  if (transaction?.context?.reward_name)
    return transaction?.context?.reward_name;
  else return transaction?.reason?.name;
}

export function generateRandomInteger(max: number) {
  return Math.floor(Math.random() * max) + 1;
}

export function createFileFromImgBase64(
  dataUrl: string,
  fileName: string,
): File | undefined {
  const arr = dataUrl.split(',');
  if (arr.length < 2) {
    return undefined;
  }
  const mimeArr = arr[0].match(/:(.*?);/);
  if (!mimeArr || mimeArr.length < 2) {
    return undefined;
  }
  const mime = mimeArr[1];
  const buff = Buffer.from(arr[1], 'base64');
  return new File([buff], fileName, { type: mime });
}

export function getReactionType(reactionType: string = 'Like') {
  if (reactionType == 'Like') {
    return ReactionTypeEnum.like;
  }
  if (reactionType == 'Love') {
    return ReactionTypeEnum.love;
  }
  if (reactionType == 'Clap') {
    return ReactionTypeEnum.clap;
  }
  if (reactionType == 'Support') {
    return ReactionTypeEnum.support;
  }
  if (reactionType == 'Celebrate') {
    return ReactionTypeEnum.celebrate;
  }
  return ReactionTypeEnum.like;
}

export function getReactionTypeIcon(reactionType: number, invert = false) {
  if (reactionType == ReactionTypeEnum.all) {
    return 'All';
  }
  let type = '';
  if (reactionType === ReactionTypeEnum.like) {
    type = 'like';
  }
  if (reactionType === ReactionTypeEnum.love) {
    type = 'love';
  }
  if (reactionType === ReactionTypeEnum.clap) {
    type = 'clap';
  }
  if (reactionType === ReactionTypeEnum.support) {
    type = 'support';
  }
  if (reactionType === ReactionTypeEnum.celebrate) {
    type = 'celebrate';
  }
  if (
    invert &&
    (reactionType === ReactionTypeEnum.like ||
      reactionType === ReactionTypeEnum.love ||
      reactionType === ReactionTypeEnum.clap)
  ) {
    return 'assets/img/svg-icons/' + type + '.svg';
  }
  return 'assets/img/svg-icons/' + type + '-icon.svg';
}

export function getProfPic(
  authData: AuthData,
  path = '',
  noLocalStorage = false,
  defaultStr = '',
) {
  if (noLocalStorage) {
    if (!path || path === defaultStr) {
      return '';
    }
    return imgUrlPrefix(path);
  }
  const profPic = authData?.tokenAuth?.user_details?.profile_pic_url;
  if (!profPic || profPic === defaultStr) {
    return '';
  }
  return imgUrlPrefix(profPic);
}

interface User {
  full_name?: string;
  first_name?: string;
  last_name?: string;
  email?: string;
  employee_id?: string;
  department_name?: string;
}

export function getFullName(
  authData: AuthData,
  user: User | number | null = null,
) {
  if (environment.settings.header?.showDepartmentInProfile) {
    return getDepartmentName(authData);
  }
  if (!user) {
    const email = authData?.tokenAuth?.user_details?.email;
    let firstName = authData?.tokenAuth?.user_details?.first_name;
    if (!firstName && !email) {
      return '';
    } else if (!firstName && !!email) {
      firstName = email.substring(0, email.indexOf('@'));
    }
    const lastName = authData?.tokenAuth?.user_details?.last_name;
    return (firstName + ' ' + (!lastName ? '' : lastName))?.trim();
  } else if (isObject(user)) {
    const _user = user as User;
    let fullName = '';
    if (!!_user?.full_name?.trim()) {
      fullName = _user?.full_name?.trim();
    }
    if (!!_user?.first_name?.trim()) {
      fullName = _user?.first_name;
    }
    if (!!_user?.last_name?.trim()) {
      fullName = fullName + ' ' + _user?.last_name;
    }
    if (!fullName?.trim()) {
      fullName = _user?.email ?? '';
    }
    return fullName?.trim();
  }
  return '';
}

export function getEmployeeId(authData: AuthData, user: User | null = null) {
  let employee_id = '';
  if (!!user) {
    employee_id = user?.employee_id?.trim();
  } else {
    employee_id = authData?.tokenAuth?.user_details?.employee_id;
  }
  const employeeIdPrefix = environment.settings.employeeIdPrefix;

  if (
    employee_id &&
    employeeIdPrefix &&
    employee_id.startsWith(employeeIdPrefix)
  ) {
    employee_id = employee_id.replace(employeeIdPrefix, '');
  }
  return employee_id || '';
}

export function getDepartmentName(
  authData: AuthData,
  user: User | null = null,
) {
  if (!user) {
    return authData?.tokenAuth?.user_details?.department_name;
  } else {
    return user?.department_name?.trim();
  }
}

export function getFullName2(user: CcUser) {
  let fullName = '';
  if (!!user?.lm_first_name?.trim()) {
    fullName = user?.lm_first_name;
  }
  if (!!user?.lm_last_name?.trim()) {
    fullName = fullName + ' ' + user?.lm_last_name;
  }
  if (!fullName?.trim()) {
    fullName = user?.lm_email ?? '';
  }
  return fullName?.trim();
}

export function getNameAcronym(fullName = '') {
  if (fullName == null) {
    fullName = '';
  }
  const matches = fullName.match(/\b(\w)/g);
  const a = !matches ? '' : matches.join('');
  if (a.length > 2) {
    return (a[0] + a[a.length - 1]).toUpperCase();
  } else if (!!a) {
    return a.toUpperCase();
  } else {
    const charSet = fullName.split(' ');
    if (charSet.length > 1) {
      return `${charSet[0][0]}${charSet[1][0]}`;
    } else {
      return charSet[0][0];
    }
  }
}

export function getAcronym(firstName: string, lastName: string) {
  if (!firstName && !lastName) {
    return '';
  } else if (!!firstName && !lastName) {
    return firstName.substring(0, 2).toUpperCase();
  } else {
    return (firstName[0] + lastName[0]).toUpperCase();
  }
}

export function isRewardEnabled(authData: AuthData) {
  return authData?.tokenAuth?.organization_settings?.enable_rewards !== 0;
}

export function getMyNameAcronym(authData: AuthData) {
  return getAcronym(
    authData?.tokenAuth?.user_details?.first_name,
    authData?.tokenAuth?.user_details?.last_name,
  );
}

export function isWithinNDay(d2: Date, n: number) {
  const d1 = new Date();
  if (
    d1.getFullYear() !== d2.getFullYear() ||
    d1.getMonth() !== d2.getMonth()
  ) {
    return false;
  }
  // @ts-ignore
  return Math.abs(d1.getDate() - d2.getDate()) <= n;
}

export function dateDiffInYears(d1: Date, d2: Date, unit = 'year') {
  const y2 = d2.getFullYear();
  const m2 = d2.getMonth();
  const dd2 = d2.getDate();
  const y1 = d1.getFullYear();
  const m1 = d1.getMonth();
  const dd1 = d1.getDate();
  if (unit === 'year') {
    let diff = y2 - y1;
    if (m1 > m2) diff--;
    else {
      if (m1 == m2) {
        if (dd1 > dd2) diff--;
      }
    }
    return diff;
  }

  return 0;
}

export function ordinalSuffix(i: number, includeNumber = false) {
  const j = i % 10;
  const k = i % 100;
  if (j == 1 && k != 11) {
    return includeNumber ? i + 'st' : 'st';
  }
  if (j == 2 && k != 12) {
    return includeNumber ? i + 'nd' : 'nd';
  }
  if (j == 3 && k != 13) {
    return includeNumber ? i + 'rd' : 'rd';
  }
  return includeNumber ? i + 'th' : 'th';
}

export function initUserInfo(authData: AuthData) {
  const a: UserProfile = {
    pk: authData?.tokenAuth?.user_details?.pk,
    email: authData?.tokenAuth?.user_details?.email,
    first_name: authData?.tokenAuth?.user_details?.first_name,
    last_name: authData?.tokenAuth?.user_details?.last_name,
    departments: [{ name: authData?.tokenAuth?.user_details?.department_name }],
    profile_img: authData?.tokenAuth?.user_details?.profile_pic_url,
  };
  a.full_name = (a.first_name + ' ' + a.last_name)?.trim();
  return a;
}

export function isPhysicalAmazonProduct(reward?: Reward): boolean {
  return (
    (reward?.redemption_type === RedemptionType.physicalProduct ||
      reward?.is_physical_product ||
      reward?.is_amazon_product) ??
    false
  );
}

export function isEVoucher(reward?: Reward | null): boolean {
  return (
    (reward?.redemption_type === RedemptionType.eVoucher ||
      reward?.redemption_type === RedemptionType.electricityTopup) ??
    false
  );
}

export function isDiscount(reward?: Reward | null): boolean {
  return (
    (reward?.redemption_type === RedemptionType.flash ||
      reward?.redemption_type === RedemptionType.flashPassword) ??
    false
  );
}

export function isMobileTopup(reward?: Reward | null): boolean {
  return reward?.redemption_type === RedemptionType.mobileTopup ?? false;
}

export function isFlash(reward?: Reward | null): boolean {
  return reward?.redemption_type === RedemptionType.flash ?? false;
}

export function isFlashPassword(reward?: Reward | null): boolean {
  return reward?.redemption_type === RedemptionType.flashPassword ?? false;
}

export function isQwikcilver(reward?: Reward | null): boolean {
  return reward?.redemption_type === RedemptionType.qwikcilver ?? false;
}

export function isMicroRedemption(reward?: Reward | null): boolean {
  return reward?.redemption_type === RedemptionType.microRedemptions ?? false;
}

export function isCashRedemption(reward?: Reward | null): boolean {
  return reward?.redemption_type === RedemptionType.cash ?? false;
}

export function isKrisFlyer(reward?: Reward | null): boolean {
  return reward?.redemption_type === RedemptionType.isKrisFlyer ?? false;
}

export function getMyUserInfo(authData: AuthData) {
  const a: UserProfile = {};
  a.email = authData?.tokenAuth?.user_details?.email;
  a.first_name = authData?.tokenAuth?.user_details?.first_name;
  a.last_name = authData?.tokenAuth?.user_details?.last_name;
  a.departments = [
    { name: authData?.tokenAuth?.user_details?.department_name },
  ];
  a.profile_img = authData?.tokenAuth?.user_details?.profile_pic_url;
  a.full_name = getFullName(authData);
  return a;
}

export function getVoucherStatusLabel(
  status: VoucherStatus,
  translate: TranslateService,
) {
  if (status === VoucherStatus.Saved) {
    return translate.instant('common.saved');
  }
  if (status === VoucherStatus.Redeemed) {
    return translate.instant('common.redeemed');
  }
  if (status === VoucherStatus.Received) {
    return translate.instant('common.received');
  }
  if (status === VoucherStatus.Expired) {
    return translate.instant('common.expired');
  }
  if (status === VoucherStatus.InProcess) {
    return translate.instant('common.inProcess');
  }
  if (status === VoucherStatus.Shipped) {
    return translate.instant('common.shipped');
  }
  if (status === VoucherStatus.OutOfStock) {
    return translate.instant('msg.qty.empty');
  }
  if (status === VoucherStatus.ReadyForPickup) {
    return translate.instant('common.readyForPickup');
  }
  if (status === VoucherStatus.Used) {
    return translate.instant('common.used');
  }
  if (status === VoucherStatus.MarkAsUsed) {
    return translate.instant('common.markAsUsed');
  }
  if (status === VoucherStatus.LinkOpened) {
    return translate.instant('common.linkOpened');
  }
  return '';
}

export function getVoucherStatusColor(status: VoucherStatus) {
  if (status === VoucherStatus.Saved) {
    return '#3C427B';
  }
  if (status === VoucherStatus.Redeemed) {
    return '#F3B24E';
  }
  if (status === VoucherStatus.Received) {
    return '#3C427B';
  }
  if (status === VoucherStatus.Expired) {
    return '#EB4C43';
  }
  if (status === VoucherStatus.InProcess) {
    return '#8A5FBA';
  }
  if (status === VoucherStatus.Shipped) {
    return '#8A5FBA';
  }
  if (status === VoucherStatus.OutOfStock) {
    return '#EB4C43';
  }
  if (status === VoucherStatus.ReadyForPickup) {
    return '#F3B24E';
  }
  if (status === VoucherStatus.Used) {
    return '#6FC969';
  }
  if (status === VoucherStatus.MarkAsUsed) {
    return '#6FC969';
  }
  if (status === VoucherStatus.LinkOpened) {
    return '#6FC969';
  }
  return null;
}

export function getVoucherStatusList(
  translate: TranslateService,
): { label: string; value: VoucherStatus }[] {
  const list = [];
  if (_voucherStatus.includes(VoucherStatus.NA)) {
    list.push({
      label: translate.instant('common.all'),
      value: VoucherStatus.NA,
    });
  }
  if (_voucherStatus.includes(VoucherStatus.Saved)) {
    list.push({
      label: getVoucherStatusLabel(VoucherStatus.Saved, translate),
      value: VoucherStatus.Saved,
    });
  }
  if (_voucherStatus.includes(VoucherStatus.Redeemed)) {
    list.push({
      label: getVoucherStatusLabel(VoucherStatus.Redeemed, translate),
      value: VoucherStatus.Redeemed,
    });
  }
  if (_voucherStatus.includes(VoucherStatus.InProcess)) {
    list.push({
      label: getVoucherStatusLabel(VoucherStatus.InProcess, translate),
      value: VoucherStatus.InProcess,
    });
  }
  if (_voucherStatus.includes(VoucherStatus.Shipped)) {
    list.push({
      label: getVoucherStatusLabel(VoucherStatus.Shipped, translate),
      value: VoucherStatus.Shipped,
    });
  }
  if (_voucherStatus.includes(VoucherStatus.ReadyForPickup)) {
    list.push({
      label: getVoucherStatusLabel(VoucherStatus.ReadyForPickup, translate),
      value: VoucherStatus.ReadyForPickup,
    });
  }
  if (_voucherStatus.includes(VoucherStatus.Received)) {
    list.push({
      label: getVoucherStatusLabel(VoucherStatus.Received, translate),
      value: VoucherStatus.Received,
    });
  }
  if (_voucherStatus.includes(VoucherStatus.LinkOpened)) {
    list.push({
      label: getVoucherStatusLabel(VoucherStatus.LinkOpened, translate),
      value: VoucherStatus.LinkOpened,
    });
  }
  if (_voucherStatus.includes(VoucherStatus.Used)) {
    list.push({
      label: getVoucherStatusLabel(VoucherStatus.Used, translate),
      value: VoucherStatus.Used,
    });
  }
  if (_voucherStatus.includes(VoucherStatus.MarkAsUsed)) {
    list.push({
      label: getVoucherStatusLabel(VoucherStatus.MarkAsUsed, translate),
      value: VoucherStatus.MarkAsUsed,
    });
  }
  if (_voucherStatus.includes(VoucherStatus.OutOfStock)) {
    list.push({
      label: getVoucherStatusLabel(VoucherStatus.OutOfStock, translate),
      value: VoucherStatus.OutOfStock,
    });
  }
  if (_voucherStatus.includes(VoucherStatus.Expired)) {
    list.push({
      label: getVoucherStatusLabel(VoucherStatus.Expired, translate),
      value: VoucherStatus.Expired,
    });
  }
  return list;
}

export function getNominationStatus(
  translate: TranslateService,
  status: NominationStatus | NominationStatusWord,
) {
  if (status === NominationStatus.submitted) {
    return translate.instant('common.submitted');
  } else if (status === NominationStatus.inReviewApprover1) {
    return translate.instant('common.inReviewApprover1');
  } else if (status === NominationStatus.inReviewApprover2) {
    return translate.instant('common.inReviewApprover2');
  } else if (
    status === NominationStatus.approved ||
    status === NominationStatusWord.approved
  ) {
    return translate.instant('common.approved');
  } else if (
    status === NominationStatus.rejected ||
    status === NominationStatusWord.rejected
  ) {
    return translate.instant('common.rejected');
  } else if (
    status === NominationStatus.draft ||
    status === NominationStatusWord.draft
  ) {
    return translate.instant('common.draft');
  } else if (status === NominationStatus.infoRequested) {
    return translate.instant('common.infoRequested');
  } else if (status === NominationStatus.resubmitted) {
    return translate.instant('common.resubmitted');
  } else if (status === NominationStatusWord.all) {
    return translate.instant('common.all');
  } else if (status === NominationStatusWord.pending) {
    return translate.instant('common.pending');
  }
  return '';
}

export function initPrimaryColor(
  color: string,
  applyBgColorFromDjango: boolean,
) {
  if (applyBgColorFromDjango && !!color) {
    document.documentElement.style.setProperty('--primary-color', color);
    document.documentElement.style.setProperty(
      '--primary-color-90',
      color + 'e6',
    );
    document.documentElement.style.setProperty(
      '--primary-color-80',
      color + 'cc',
    );
    document.documentElement.style.setProperty(
      '--primary-color-70',
      color + 'b3',
    );
    document.documentElement.style.setProperty(
      '--primary-color-60',
      color + '99',
    );
    document.documentElement.style.setProperty(
      '--primary-color-50',
      color + '80',
    );
    document.documentElement.style.setProperty(
      '--primary-color-40',
      color + '66',
    );
    document.documentElement.style.setProperty(
      '--primary-color-30',
      color + '4d',
    );
    document.documentElement.style.setProperty(
      '--primary-color-20',
      color + '33',
    );
    document.documentElement.style.setProperty(
      '--primary-color-10',
      color + '1a',
    );
  }
}

export function isPaymentEnabled(authData: AuthData) {
  return !!authData?.tokenAuth?.currency;
}

let successCounter = 6;
let infoCounter = 6;
let errorCounter = 6;

export function showInfo(
  msg: string,
  toastr: ToastrService,
  translate: TranslateService,
  isKey = false,
) {
  const x = isKey ? translate.instant(msg) : msg;
  if (infoCounter === 6) {
    infoCounter--;
    const timer = setInterval(() => {
      if (infoCounter === 5) {
        toastr.info(x, translate.instant('common.information'), {
          timeOut: 5000,
        });
      }
      infoCounter--;
      if (infoCounter === 0) {
        clearInterval(timer);
        infoCounter = 6;
      }
    }, 1000);
  }
  return x;
}

export function showSuccess(
  msg: string,
  toastr: ToastrService,
  translate: TranslateService,
  isKey = false,
) {
  const x = isKey ? translate.instant(msg) : msg;
  if (successCounter === 6) {
    successCounter--;
    const timer = setInterval(() => {
      if (successCounter === 5) {
        toastr.success(x, translate.instant('common.success._'), {
          timeOut: 5000,
        });
      }
      successCounter--;
      if (successCounter === 0) {
        clearInterval(timer);
        successCounter = 6;
      }
    }, 1000);
  }
  return x;
}

export function getError(
  err: any,
  translate: TranslateService,
  defaultDataRespMsg: string = '',
) {
  if (err?.response?.status >= 500 && err?.response?.status <= 599) {
    return err?.message + ': ' + err?.response?.statusText;
  } else if (!!err?.response?.data) {
    if (!!defaultDataRespMsg) {
      return defaultDataRespMsg;
    } else if (
      typeof err?.response?.data === 'string' ||
      typeof err?.response?.data === 'number'
    ) {
      return err?.response?.data;
    } else if (err?.response?.data instanceof Object) {
      if (Array.isArray(err?.response?.data)) {
        return err?.response?.data[0];
      } else if ('error' in err?.response?.data) {
        if (Array.isArray(err?.response?.data?.error)) {
          return err?.response?.data?.error[0];
        } else {
          return err?.response?.data?.error;
        }
      } else if ('detail' in err?.response?.data) {
        if (Array.isArray(err?.response?.data?.detail)) {
          return err?.response?.data?.detail[0];
        } else {
          return err?.response?.data?.detail;
        }
      } else if ('non_field_errors' in err?.response?.data) {
        if (Array.isArray(err?.response?.data?.non_field_errors)) {
          return err?.response?.data?.non_field_errors[0];
        } else {
          return err?.response?.data?.non_field_errors;
        }
      } else if ('quantity' in err?.response?.data) {
        if (Array.isArray(err?.response?.data?.quantity)) {
          return err?.response?.data?.quantity[0];
        } else {
          return err?.response?.data?.quantity;
        }
      } else if ('timestamp' in err?.response?.data) {
        if (Array.isArray(err?.response?.data?.non_field_errors)) {
          return err?.response?.data?.timestamp[0];
        } else {
          return err?.response?.data?.timestamp;
        }
      } else if ('title' in err?.response?.data) {
        if (Array.isArray(err?.response?.data?.title)) {
          return err?.response?.data?.title[0];
        } else {
          return err?.response?.data?.title;
        }
      }
    } else {
      return err?.response?.data;
    }
  } else if (!!err?.message) {
    return err?.message;
  }
  if (Array.isArray(err)) {
    return err[0];
  }
  return translate.instant('msg.error._');
}

/**
 * This function intended is for API debug purpose only. Please enable the option on white-label settings to use this function.
 * White-label settings:
 * - debug.api.showFailedStatusCodeInToastr to show error in toastr
 * - debug.api.showFailedStatusCodeInBrowserConsole to show error in browser console
 */
export function showDebug(
  err: any,
  toastr: ToastrService,
  translate: TranslateService,
) {
  if (_debug.api.showFailedStatusCodeInToastr) {
    showInfo(
      err?.response?.status + ': ' + err?.response?.statusText,
      toastr,
      translate,
      true,
    );
  }
  if (_debug.api.showFailedStatusCodeInBrowserConsole) {
    console.log(err);
  }
}

export function showError(
  err: any,
  toastr: ToastrService,
  translate: TranslateService,
  override: boolean = false,
  isKey: boolean = false,
) {
  let msg: any = '';
  if (
    err?.response?.status === 401 &&
    err?.response?.data?.detail === 'Invalid token.'
  ) {
    showInfo('authentication.expiredSession', toastr, translate, true);
    clearLocalStorage();
    window.open('login', '_self');
  }
  if (override) {
    if (isKey) {
      msg = translate.instant(err);
    } else {
      const status = err?.response?.status || 200;
      msg = status >= 500 ? translate.instant('msg.server.error') : err;
    }
  } else {
    const status = err?.response?.status || 200;
    msg =
      status >= 500
        ? translate.instant('msg.server.error')
        : getError(err, translate);
  }
  if (errorCounter === 6) {
    errorCounter--;
    const timer = setInterval(() => {
      if (errorCounter === 5 && err?.response?.status != 401) {
        toastr.error(msg, translate.instant('common.error'), { timeOut: 5000 });
      }
      errorCounter--;
      if (errorCounter === 0) {
        clearInterval(timer);
        errorCounter = 6;
      }
    }, 1000);
  }
  return msg;
}

export async function showError2(
  err: any,
  toastr: ToastrService,
  translate: TranslateService,
  router: Router,
) {
  if (err?.response?.status >= 100 && err?.response?.status <= 399) {
    showDebug(err, toastr, translate);
  } else if (err?.response?.status >= 400 && err?.response?.status <= 499) {
    if (
      err?.response?.status === 401 &&
      err?.response?.data?.detail === 'Invalid token.'
    ) {
      showInfo('authentication.expiredSession', toastr, translate, true);
      clearLocalStorage();
      await router.navigate(['/login']);
    } else if (!!err?.response?.data) {
      showError(
        err?.response?.status + ': ' + stripHtml(err?.response?.data),
        toastr,
        translate,
        true,
      );
    } else if (!!err?.response?.statusText) {
      showError(
        err?.response?.status + ': ' + err?.response?.statusText,
        toastr,
        translate,
        true,
      );
    }
  } else if (err?.response?.status >= 500 && err?.response?.status <= 599) {
    showError(err, toastr, translate);
  }
}

export function showWarning(
  err: any,
  toastr: ToastrService,
  translate: TranslateService,
  override: boolean = false,
  isKey: boolean = false,
) {
  let msg: any = getError(err, translate);
  if (override) {
    msg = isKey ? translate.instant(err) : err;
  }
  toastr.warning(msg, translate.instant('common.warning'), { timeOut: 5000 });
  return msg;
}

export function enabledModuleCounts(modules: any) {
  let a = modules?.home ? 1 : 0;
  a += modules?.rewards ? 1 : 0;
  a += modules?.myWallet ? 1 : 0;
  a += modules?.settings ? 1 : 0;
  a += modules?.appreciate ? 1 : 0;
  a += modules?.feeds ? 1 : 0;
  a += modules?.calendar ? 1 : 0;
  a += modules?.pointHistory ? 1 : 0;
  return a;
}

export function setTitle(
  translate: TranslateService,
  lang: LangService,
  titleS: Title,
  title: string,
  notKey = false,
) {
  const siteTitle = environment.settings.appName;
  if (notKey) {
    titleS.setTitle(title + ' | ' + siteTitle);
  } else {
    titleS.setTitle(translate.instant(title) + ' | ' + siteTitle);
  }
  lang.langUpdated.subscribe(async () =>
    titleS.setTitle(title + ' | ' + siteTitle),
  );
}

export function debounce(ms: number) {
  return (
    target: unknown,
    propertyKey: string,
    descriptor: PropertyDescriptor,
  ): void => {
    let timer: ReturnType<typeof setTimeout>;
    const originalMethod = descriptor.value;

    descriptor.value = function (...args: any[]) {
      new Promise((resolve) => {
        if (timer) {
          clearTimeout(timer);
        }

        timer = setTimeout(() => {
          resolve(originalMethod.apply(this, ...args));
        }, ms);
      });
    };
  };
}

export function getvoucherMonthTranslation(
  month: string,
  translate: TranslateService,
) {
  return translate.instant('common.months.' + month);
}
export function convetObjectToArrayOfKeyValue(obj: Object): ObjectI[] {
  const a: ObjectI[] = [];
  if (!!obj) {
    for (let [key, value] of Object.entries(obj)) {
      a.push({ key, value });
    }
  }
  return a;
}

export function greetingTypeLabel(translate: TranslateService, item: Greeting) {
  if (item?.type === GreetingType.anniversary) {
    return translate.instant('common.anniversary');
  } else if (item?.type === GreetingType.birthday) {
    return translate.instant('common.birthday');
  }
  return '';
}

export function stripHtml(html: string): string {
  let tmp = document.createElement('DIV');
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || '';
}

export function getReceiptStatusLabel(
  status: ReceiptStatus,
  translate: TranslateService,
) {
  if (status === ReceiptStatus.processing) {
    return translate.instant('common.processing');
  } else if (status === ReceiptStatus.approved) {
    return translate.instant('common.approved');
  } else if (status === ReceiptStatus.rejected) {
    return translate.instant('common.rejected');
  } else if (status === ReceiptStatus.infoRequested) {
    return translate.instant('common.infoRequested');
  } else if (status === ReceiptStatus.pending) {
    return translate.instant('common.pending');
  } else if (status === ReceiptStatus.autoRejected) {
    return translate.instant('common.autoRejected');
  } else if (status === ReceiptStatus.onHold) {
    return translate.instant('common.onHold');
  } else {
    return '';
  }
}

export function toggleStyleDisplay(
  style: Partial<CSSStyleDeclaration>,
  open = 'block',
) {
  if (style.display === open) {
    style.display = 'none';
  } else {
    style.display = open;
  }
}

export function localDate(date: string | null = null) {
  const d = !date ? dayjs() : dayjs(date);
  return d.tz(dayjs.tz.guess()).local();
}

export function getTierBtnColor(tier: string | RewardTiers) {
  if (tier == RewardTiers.Bronze) {
    return RewardTiersColors.Bronze;
  } else if (tier == RewardTiers.Silver) {
    return RewardTiersColors.Silver;
  } else if (tier == RewardTiers.Diamond) {
    return RewardTiersColors.Diamond;
  } else {
    return RewardTiersColors.none;
  }
}

export function getTierName(
  tier: string | RewardTiers,
  translate: TranslateService,
) {
  if (tier == RewardTiers.Bronze) {
    return translate.instant('common.bronze');
  } else if (tier == RewardTiers.Silver) {
    return translate.instant('common.silver');
  } else if (tier == RewardTiers.Diamond) {
    return translate.instant('common.diamond');
  } else {
    return '';
  }
}

export function isGroupable(x1: PostPollResult, x2: PostPollResult) {
  return (
    x1.created_by === x2.created_by &&
    x1.created_on === x2.created_on &&
    x1.title === x2.title &&
    x1.description === x2.description &&
    x1.post_type === x2.post_type &&
    x1.feed_type === x2.feed_type &&
    x1.time_left === x2.time_left &&
    x1.nomination?.category === x2.nomination?.category &&
    x1.nomination?.nominator_name === x2.nomination?.nominator_name &&
    x1.nomination?.created === x2.nomination?.created &&
    x1.nomination?.badge?.id === x2.nomination?.badge?.id
  );
}

export function toBase64(file: File) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });
}

export function convertFile(url: string, name: string) {
  return fetch(url)
    .then((res: Response) => res.blob())
    .then((blob: Blob) => new File([blob], name))
    .catch((e) => null);
}

type Option = {
  lowerCase?: boolean;
  upperCase?: boolean;
  enableNumber?: boolean;
};

export function generateRandomString(length: number, option: Option) {
  let result = '';
  let characters = '';
  if (option.lowerCase) {
    characters += 'abcdefghijklmnopqrstuvwxyz';
  }
  if (option.upperCase) {
    characters += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  }
  if (option.enableNumber) {
    characters += '0123456789';
  }
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

export function ableToSubmitNomination(nomCat: NominationCategory) {
  if (nomCat?.rating_process === RatingProcess.inProgress) {
    return { ok: false, message: 'msg.nomination.submit.unable.0' };
  } else if (nomCat?.rating_process === RatingProcess.completed) {
    return { ok: false, message: 'msg.nomination.submit.unable.1' };
  } else if (nomCat?.available_for === AvailableFor.reviewOnly) {
    return { ok: false, message: 'msg.nomination.submit.unable.2' };
  } else if (nomCat?.available_for === AvailableFor.ratingOnly) {
    return { ok: false, message: 'msg.nomination.submit.unable.3' };
  }
  return { ok: true, message: null };
}

export function ableToReviewNomination(nomCat: NominationCategory) {
  if (nomCat?.rating_process === RatingProcess.inProgress) {
    return { ok: false, message: 'msg.nomination.submit.unable.0' };
  } else if (nomCat?.rating_process === RatingProcess.completed) {
    return { ok: false, message: 'msg.nomination.submit.unable.1' };
  } else if (nomCat?.available_for === AvailableFor.ratingOnly) {
    return { ok: false, message: 'msg.nomination.submit.unable.3' };
  }
  return { ok: true, message: null };
}

export function validateMobileNumber(mobile: string, length): boolean {
  if (mobile.length == length && mobile.match('^[0-9]*$')) {
    return true;
  } else {
    return false;
  }
}

export function jsonToFormData(json: any) {
  let formData = new FormData();
  for (var key in json) {
    formData.append(key, json[key]);
  }
  return formData;
}

export function dataURLtoFile(dataurl: string, filename: string) {
  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[arr.length - 1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

export function camelCase(str) {
  // Using replace method with regEx
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index == 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, '');
}

export function validateMobileNumberWithCountryCode(phone_number: string) {
  let countryCode = environment.settings.address.countryCode;
  if (phone_number.startsWith('+91')) {
    let numberWithoutCountryCode = phone_number.substring(3);
    if (validateMobileNumber(numberWithoutCountryCode, 10)) {
      return phone_number;
    } else {
      return false;
    }
  } else if (phone_number.startsWith('+65')) {
    let numberWithoutCountryCode = phone_number.substring(3);

    if (validateMobileNumber(numberWithoutCountryCode, 8)) {
      return phone_number;
    } else {
      return false;
    }
  } else if (phone_number.startsWith('0')) {
    let trimmed = phone_number.substring(1);

    if (validateMobileNumber(trimmed, 9)) {
      return countryCode + trimmed;
    } else {
      return false;
    }
  } else {
    if (!validateMobileNumber(phone_number, 9)) {
      return countryCode + phone_number;
    } else {
      return false;
    }
  }
}

export function checkRealMoneyisNull(cartDetails: CartRewardDetail) {
  return cartDetails && cartDetails?.real_money == null ? true : false;
}

export function isUserSameAsLoggedInUser(
  authData: AuthData,
  userCheckPK: number,
) {
  return authData?.tokenAuth?.user_details?.pk == userCheckPK ? true : false;
}

export function compareDate(date, currentDate) {
  const convertedDate = new Date(date);
  const now = new Date(currentDate);
  if (convertedDate < now) return PeriodType.past;
  if (convertedDate > now) return PeriodType.future;
  return PeriodType.present;
}

export function capitalizeFirstLetter(value: string): string {
  if (!value) {
    return value;
  }
  return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
}

export function isAppreciateEnabled(authData: AuthData) {
  return authData?.tokenAuth?.organization_settings?.p2p_points_limit !== 0;
}

export function isPostPollEnabled(authData: AuthData) {
  return authData?.tokenAuth?.organization_settings?.enable_news_feed !== 0;
}

export function isNominationEnabled(authData: AuthData) {
  return authData?.tokenAuth?.organization_settings?.enable_nominations !== 0;
}

export function isLeaderboardEnabled(authData: AuthData) {
  return authData?.tokenAuth?.organization_settings?.hide_leaderboard == 0;
}

export function isSalesReceiptEnabled(authData: AuthData) {
  return authData?.tokenAuth?.organization_settings?.enable_receipts_upload === 1;
}

export function isBenefitReceiptEnabled(authData: AuthData) {
  return authData?.tokenAuth?.organization_settings?.enable_benefit_receipts_upload === 1;
}

export function sanitizeHtml(html: string): SafeHtml {
  return html
    .replace(/\r\n/g, '<br/>')
    .replace(/\n/g, '<br/>')
    .replace(/\r/g, '<br/>');
}
