import {
  Component,
  inject,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { updateTokenAuth } from 'src/app/state/auth-data/auth-data.calls';
import { AuthData, TokenAuth } from 'src/app/utility/model/token-auth';
import { MatDrawer } from '@angular/material/sidenav';
import Breadcrumb from '../../utility/model/breadcrumb';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs/internal/Subscription';
import { fromEvent, merge, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { PasswordChangeModalComponent } from 'src/app/settings/password-change-modal/password-change-modal.component';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/services/api-services/api.service';
import {
  initPrimaryColor,
  showError,
  showError2,
} from 'src/app/utility/common-logic';
import { SharedService } from 'src/app/services/shared.service';
import {
  clearLocalStorage,
  saveLocalStorage,
} from 'src/app/utility/local-storage';
import { UserProfile } from 'src/app/utility/model/user-profile';
import { ConsentBoxComponent } from '../consent-box/consent-box.component';
import { AxiosService } from 'src/app/services/axios.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit, OnDestroy {
  authData: Partial<AuthData> = {};
  hasBackdrop = true;
  mode: 'over' | 'push' | 'side' = 'over';
  @Input() hideHeader = '';
  @Input() stickyHeader = '';
  @Input() hideFooter = environment.settings.showFooter === '1' ? '' : '1';
  @Input() stickyFooter = '';
  @ViewChild('leftDrawer') leftDrawer: MatDrawer | undefined;
  @ViewChild('rightDrawer') rightDrawer: MatDrawer | undefined;
  @Input() breadcrumb: Breadcrumb[] = [];
  @Input() layoutBgColor = '';
  @Input() homePage = false;
  @Input() loadApi = true;
  hideLeftDrawer = false;
  hideRightDrawer = false;
  rightDrawerIndex = 0;
  networkStatus = false;
  networkStatus$: Subscription = Subscription.EMPTY;
  applyBgColorFromDjango = environment.settings.applyBgColorFromDjango;
  loaded = false;
  sessionTime: number;
  showBottomMenu = environment.settings.showBottomMenu;
  appName = environment.settings?.appName;
  leftNavigate = environment.settings.leftNavigate;
  showServiceBanner = environment.settings.showServiceBanner;
  showSessionTimer = environment.settings.showSessionTimer;
  showSessionTimerNote = environment.settings.showSessionTimerNote;
  freshchat = environment.settings.freshchat;
  showTimer = environment.settings.showMobileTimer;
  hasAcceptedTermsCondition = Boolean(
    localStorage.getItem('has_accepted_terms_and_cond') ?? false,
  );
  app = environment.settings.app;

  timerStarted = false;
  showMobileTimer = false;

  constructor(
    private authDataStore: Store<{ authData: AuthData }>,
    private router: Router,
    public translate: TranslateService,
    public dialog: MatDialog,
    private toastr: ToastrService,
    private api: ApiService,
    private shared: SharedService,
    private axiosService: AxiosService,
  ) {}

  get isLoginTnc() {
    return this.router.url === '/login-tnc';
  }

  async ngOnInit() {
    this.authDataStore
      .select('authData')
      .subscribe((res: Partial<AuthData>) => {
        this.authData = res;
      });
    initPrimaryColor(
      localStorage.getItem('background-color') ||
        this.authData?.tokenAuth?.organization_details?.background_color,
      this.applyBgColorFromDjango,
    );
    if (
      !this.isLoginTnc &&
      (localStorage.length === 0 || !localStorage.getItem('token-auth'))
    ) {
      await this.router.navigate(['/login']);
    } else {
      if (this.loadApi) {
        await this.getUserData();
      }
    }

    if (this.shared.isMobile() && this.showTimer) {
      this.showMobileTimer = true;
    }

    this.axiosService.configureAxios();
    this.checkNetworkStatus();
    this.checkPassword();
  }

  ngOnDestroy() {
    this.networkStatus$.unsubscribe();
  }

  // To check internet connection stability
  checkNetworkStatus() {
    this.networkStatus = navigator.onLine;
    this.networkStatus$ = merge(
      of(null),
      fromEvent(window, 'online'),
      fromEvent(window, 'offline'),
    )
      .pipe(map(() => navigator.onLine))
      .subscribe((status) => {
        this.networkStatus = status;
        if (!status) {
          showError(
            'msg.internet.lost',
            this.toastr,
            this.translate,
            true,
            true,
          );
        }
      });
  }

  checkPassword() {
    if (this.authData?.tokenAuth?.user_details?.using_default_password) {
      this.dialog.open(PasswordChangeModalComponent, {
        disableClose: true,
        maxWidth: '90vw',
        maxHeight: '90vh',
        data: { isModal: true },
      });
    }
  }

  async getUserData() {
    this.loaded = true;
    await this.api
      .getUserDetails()
      .then(async (res: TokenAuth) => {
        this.loaded = true;
        localStorage.setItem('country', res.organization_details?.country);
        await this.api
          .getUserProfile()
          .then(
            (res2: UserProfile) =>
              (res.user_details = { ...res?.user_details, ...res2 }),
          )
          .catch((err) => showError(err, this.toastr, this.translate));
        await this.api.getAllOrganizationSettings().then(
          (res2) =>
            (res.organization_settings = {
              ...res?.organization_settings,
              ...res2,
            }),
        );
        saveLocalStorage(res);
        updateTokenAuth(this.authDataStore, this.authData, res);
        initPrimaryColor(
          localStorage.getItem('background-color') ||
            res.organization_details.background_color,
          this.applyBgColorFromDjango,
        );
        if (
          res.organization_details?.ask_to_accept_terms_and_conditions >= 2 &&
          !this.hasAcceptedTermsCondition &&
          !res.user_details?.has_accepted_terms_and_cond
        ) {
          this.openConsentBox();
        }
        this.shared.spinner = false;
      })
      .catch((err) => showError2(err, this.toastr, this.translate, this.router))
      .finally(() => (this.loaded = false));
  }

  toggleDrawer(direction: 'left' | 'right') {
    if (direction === 'left') {
      this.hideLeftDrawer = false;
      this.leftDrawer?.toggle();
    }
    if (direction === 'right') {
      this.hideRightDrawer = false;
      this.rightDrawer?.toggle();
    }
  }

  get style(): { [p: string]: any } {
    const style: { [p: string]: any } = {};
    if (!!this.stickyHeader) {
      style['marginTop'] = !this.breadcrumb.length ? '80px' : '120px';
    }
    return style;
  }

  openConsentBox() {
    const dialogRef = this.dialog.open(ConsentBoxComponent, {
      disableClose: true,
      maxWidth: '90vw',
      maxHeight: '90vh',
      data: { isModal: true },
    });
  }
}
